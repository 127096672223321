
.e-input-group.gray-input,
.e-input-group.e-control-wrapper.gray-input,
.e-float-input.gray-input,
.e-float-input.e-input-group.gray-input,
.e-float-input.e-control-wrapper.gray-input,
.e-float-input.e-input-group.e-control-wrapper.gray-input {
    background: #f9fafb;
}

.e-input-group.focused-input,
.e-input-group.e-control-wrapper.focused-input,
.e-float-input.focused-input,
.e-float-input.e-input-group.focused-input,
.e-float-input.e-control-wrapper.focused-input,
.e-float-input.e-input-group.e-control-wrapper.focused-input {
    border: 1px solid #4f46e5 !important;
}

.bold-header.e-grid .e-headercelldiv {
    font-weight: bold !important;
}

.e-float-input.e-control-wrapper.required-input .e-float-text::after {
    content: '*';
    color: red;
}

.e-dialog .e-dlg-content {
    padding-top: 0 !important;
    background: white;
}
.e-dialog .e-dlg-header {
    background: white;
}
.e-dlg-header-content {
    background: white;
}
.e-dialog .e-footer-content {
    background: white;
}
.e-dialog  {
    background: white;
}

