// Import Fuse core library
@import "@fuse/scss/core";
@import "@fuse/scss/aster/aster";
// Import app.theme.scss
@import "app/app.theme";
@import "@fuse/scss/introjs";
@import "assets/styles/custom-header-syncfusion.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


angular-editor_toolbar
button i {
    font-family: FontAwesome ! important;
    color: #474D6D !important;
}
angular-editor-toolbar button i { font-family: FontAwesome !important; color: #474d6d !important; }

.multi-line-snackbar {
    white-space: pre-wrap;
}
.hide-translate-text {
    font-size: 0;
}
.title-fieldset {
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 700;
}

fieldset {
    border-radius: 12px;
    border-color: #d6d7d9;
    border-style: dotted;
}
.e-badge{
    border-radius: 8px;
}
.spinner-wrapper {
    display: flex;
    justify-content: center;
}
.custom-badge-container {
    display: flex;
    align-items: center;
}
.custom-badge {
    margin-inline: 2%;
}
.custom-badge .mat-badge-content {
    background: red !important;
}
@keyframes blinks-animation {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.1;
    }
}
.blinking {
    animation: blinks-animation 1s infinite alternate;
}
.e-spinner-pane .e-spinner-inner .e-spin-material {
    stroke: #1DE5CB !important;
}
